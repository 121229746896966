<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
		></pui-datatable>
	</div>
</template>

<script>
import shipActions from './ShipActions';

export default {
	name: 'ship-grid',
	components: {},
	data() {
		return {
			modelName: 'ship',
			actions: shipActions.gridactions,
			modelColumnDefs: {
				creationdatetime: { withoutHours: true },
				downdatetime: { withoutHours: true }
			},
			masterDetailColumnVisibles: {
				imo: true,
				name: true,
				creationdatetime: true,
				downdatetime: true,
				gt: true,
				flag: true,
				shiptype: true
			}
		};
	}
};
</script>
